/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import FlamboroughData from 'views/DemoModels/GeoTour3DData';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const FlamboroughPage = () => {
  return <WithLayout component={FlamboroughData} layout={Main}  modelID={2}/>;
};

export default FlamboroughPage;
